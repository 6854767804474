export const getCookieValue = (name, cookie) => {
    // we need to check for document here, otherwise during build,
    // gatsby will throw "document is undefined" during server-side rendering
    if (typeof document === `undefined`) {
        return ``
    }
    const c = cookie || document.cookie
    const re = new RegExp(`${name}=([^;]+)`)
    const value = re.exec(c)
    return value != null ? unescape(value[1]) : null
}

export const decodeStartpagePreferences = (str) => {
    return str.replace(/EEE/g, '=').replace(/N1N/g, '&')
}
