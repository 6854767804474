export const stripTags = (htmlString) => {
    return htmlString.replace(/(<([^>]+)>)/gi, '')
}

export const decodeHTMLEntities = (text) => {
    if (typeof document === `undefined`) {
        return ``
    }
    const textArea = document.createElement('textarea')
    textArea.innerHTML = text
    return textArea.value
}
