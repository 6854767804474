import {getStartpageBaseOrigin, getUrlParameterByName} from './url'
import {getCookieValue, decodeStartpagePreferences} from './cookie'

export const possibleThemes = new Set(['light', 'air', 'dark', 'night'])

export const lightThemes = new Set(['light', 'air'])

const baseOrigin = getStartpageBaseOrigin()

export const themes = {
    light: {
        primaryTextColor: '#202945',
        secondaryTextColor: '#2e39b3',
        tertiaryTextColor: '#7F869E',
        quaternaryTextColor: '#7F869E',
        heroTextColor: '#fff',
        linkColor: '#6573ff',
        heroBackgroundColor: '#6573ff',
        lighterBackgroundColor: '#fff',
        darkerBackgroundColor: '#FBFBFD',
        darkerBackgroundColor2: '#FBFBFD',
        emphasisBackgroundColor: '#2e39b3',
        emphasisTextColor: '#6573ff',
        whiteFilter: '',
        quoteTextColor: '#fff',
        quoteBackgroundColor: '#202a45',
        whisper: '#FBFBFB',
        primaryBackgroundColor: '#FFFFFF',
        secondaryBackgroundColor: '#FBFBFB',
        highlightBackgroundColor: '#F2F3FF',
        submitBackgroundColor: '#F2F3FF',
        chartBorderColor: '#FFFFFF',
        boxBorderColor: '#EBECF7',
        brandBlue: '#6573ff',
        brandBlueLight: '#DAE0FF',
        brandBlueAlt: '#384775',
        submitColor: '#384775',
        heroMaskColor: '#DAE0FF',
        oddListItem: '#F5F5FB',
        error: '#8f0c12',
        headerLinkColor: '#374677',
        borderRadius: '4px',
        borderColor: '#dee0f7',
        borderRight: '#ebecf7',
        borderHover: '#6573ff',
        productButtonColor: '#FDB22B',
        heroGradientFirst: '#7985FF',
        heroGradientLast: '#525FE7',
        ourValuesBackground: '#FBFBFB',
        ourValuesItems: '#fff',
        ourTeamBackground: '#fff',
        ourProductsBackground: '#fff',
        ourProductsItems: '#fff',
        ourOrganizationsBackground: '#FBFBFB',
        ourLinksBackground: '#fff',
        ourLinksItems: '#fff',
        placeholder: '#C3C8DA',
        privacyBoxBackground: '#EBECF7',
        privacyBoxBorder: '#EBECF7',
        privacyBackground: '#FBFBFD',
        privacyTabHighlight: '#F2F3FF',
        searchSuggestBackgroundColor: '#ffffff',
        searchSuggestBackgroundHoverColor: '#ebecf7',
        footerLinkColor: '#374677',
        errorRed: '#E23D3D',
        searchButtonUrl: `${baseOrigin}/sp/cdn/images/magnifying-glass-grey.svg`,
        searchButtonUrlHover: `${baseOrigin}/sp/cdn/images/magnifying-glass.svg`,
    },
    dark: {
        primaryTextColor: '#fff',
        secondaryTextColor: '#c5c5c5',
        tertiaryTextColor: '#C3C8DA',
        quaternaryTextColor: '#5B627A',
        heroTextColor: '#cfd1dd',
        linkColor: '#A6AFFF',
        heroBackgroundColor: '#000',
        lighterBackgroundColor: '#171B25',
        darkerBackgroundColor: '#000000',
        darkerBackgroundColor2: '#000000',
        emphasisBackgroundColor: '#6573ff',
        emphasisTextColor: '#A6AFFF',
        whiteFilter: 'brightness(20)',
        quoteTextColor: '#fff',
        quoteBackgroundColor: '#202a45',
        whisper: '#0F1219',
        primaryBackgroundColor: '#171B25',
        secondaryBackgroundColor: '#0F1219',
        brandFontColor: '#A6AFFF',
        highlightBackgroundColor: '#3A445C',
        submitBackgroundColor: '#3B435B',
        chartBorderColor: '#3A445C',
        boxBorderColor: '#3A445C',
        brandBlue: '#6573ff',
        brandBlueLight: '#DAE0FF',
        submitColor: '#EBECF7',
        heroMaskColor: '#252B3B',
        oddListItem: '#252B3B',
        error: '#8f0c12',
        headerLinkColor: '#f2f3fb',
        borderRadius: '4px',
        borderColor: '#3b435b',
        borderRight: '#7f869e',
        borderHover: 'rgb(167, 177, 252)',
        productButtonColor: '#29DDCC',
        heroGradientFirst: '#444FC2',
        heroGradientLast: '#6573FF',
        ourValuesBackground: '#0c0d0f',
        ourValuesItems: '#1E222D',
        ourTeamBackground: '#171b25',
        ourProductsBackground: '#171b25',
        ourProductsItems: '#1E222D',
        ourOrganizationsBackground: '#0c0d0f',
        ourLinksBackground: '#171b25',
        ourLinksItems: '#1E222D',
        placeholder: '#C3C8DA',
        privacyBoxBackground: 'transparent',
        privacyBoxBorder: '#29DDCC',
        privacyBackground: '#0C0D0F',
        privacyTabHighlight: '#0C0D0F',
        searchSuggestBackgroundColor: '#171b25',
        searchSuggestBackgroundHoverColor: '#3a445c',
        footerLinkColor: '#ebecf7',
        errorRed: '#F77779',
        searchButtonUrl: `${baseOrigin}/sp/cdn/images/magnifying-glass-whisper-dark.svg`,
        searchButtonUrlHover: `${baseOrigin}/sp/cdn/images/magnifying-glass-whisper-dark.svg`,
    },
}

const getThemeFromCookie = () => {
    const preferencesCookie = getCookieValue('preferences')
    if (preferencesCookie) {
        const decodedPreferences = decodeStartpagePreferences(preferencesCookie)
        const languageCode = getUrlParameterByName(
            'lang_homepage',
            `http://some-url.com?${decodedPreferences}`, // make it into a url format for the sake of using getUrlParameterByName
        )
        const theme = languageCode.split('/')[1]
        return theme || null
    }
    return null
}

const getThemeFromPfrhUrlParam = () => {
    const preferencesUrl = getUrlParameterByName('prfh')
    if (preferencesUrl) {
        const decodedPreferences = decodeStartpagePreferences(preferencesUrl)
        const languageCode = getUrlParameterByName(
            'lang_homepage',
            `http://some-url.com?${decodedPreferences}`, // make it into a url format for the sake of using getUrlParameterByName
        )
        const theme = languageCode.split('/')[1]
        return theme || null
    }
    const theme = getUrlParameterByName('t')
    return theme
}

export const osPrefersDarkTheme = () => {
    if (typeof window !== `undefined`) {
        // if dark theme css check not supported
        if (!window.matchMedia) return false
        return window.matchMedia('(prefers-color-scheme: dark)').matches
    }
    return false
}

export const getTheme = () => {
    const cookieTheme = getThemeFromCookie()
    const urlTheme = getThemeFromPfrhUrlParam()
    let resolvedTheme
    // in order of preferred
    if (possibleThemes.has(urlTheme)) {
        resolvedTheme = urlTheme
    } else if (possibleThemes.has(cookieTheme)) {
        resolvedTheme = cookieTheme
    }
    if (resolvedTheme === 'air') return 'light'
    if (resolvedTheme === 'night') return 'dark'
    if (resolvedTheme) return resolvedTheme
    if (osPrefersDarkTheme()) return 'dark'
    return 'light'
}

export const getThemeStyles = (themeParam) => {
    const theme = themeParam || getTheme()
    if (theme) {
        return themes[theme]
    }
    return themes.light
}

export const getThemedImage = (images, theme) => {
    // TODO: return "default" for "light" and "dark" for "black"
    // as these images are coming from DatoCMS, update DatoCMS later.
    const simplifiedTheme = lightThemes.has(theme) ? 'default' : 'dark'
    return images.find((img) => img.customData.theme === simplifiedTheme)
}
