/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import {Global, css} from '@emotion/core'
import {StaticQuery, graphql} from 'gatsby'
import Header from './Header'
import Footer from './Footer'
import HeaderError from './error/header'

import SEO from './Seo'

import {globalStyles} from '../constants'

const OuterLayout = ({children, title, description, canonicalPath, noindex, themeStyles, lang}) => {
    return (
        <div
            css={css`
                opacity: ${themeStyles && JSON.stringify(themeStyles) === '{}' ? '0' : '1'};
                transition: opacity 0.05s ease;
                height: 100vh;
            `}
        >
            <SEO title={title} description={description} lang={lang} canonicalPath={canonicalPath} noindex={noindex} />
            <Global styles={globalStyles} />
            <main
                css={css`
                    background: ${themeStyles.lighterBackgroundColor || '#fff'};
                    height: 100%;
                `}
            >
                {children}
            </main>
        </div>
    )
}

const Layout = ({
    children,
    title,
    description,
    lang,
    canonicalPath,
    themeStyles,
    theme,
    noHeaderFooter,
    errorHeader = false,
    noindex = false,
    bannerTitle = "",
    hidePrivacyDropdown = false,
    headerOverrideStyles = ``,
    noFooter = false,
}) =>
    noHeaderFooter ? (
        <OuterLayout
            title={title}
            description={description}
            canonicalPath={canonicalPath}
            noindex={noindex}
            themeStyles={themeStyles}
            lang={lang}
        >
            {children}
        </OuterLayout>
    ) : (
        <StaticQuery
            query={graphql`
                query LayoutQuery {
                    site {
                        siteMetadata {
                            ...HeaderData
                            ...HamburgerData
                            ...FooterData
                        }
                    }
                }
            `}
            render={(data) => {
                const {
                    headerTitle,
                    headerCarrotAsset,
                    headerSettingsLink,
                    headerPrivacyAsset,
                    headerPrivacyAssetDark,
                    headerPrivacyDescription,
                    headerPrivacyLink,
                    headerSettingsDescription,
                    headerSettingsAsset,
                    headerSettingsTitle,
                    headerHowLink,
                    headerBlogDescription,
                    headerBlogLink,
                    headerExtension1,
                    headerExtensionBold,
                    headerExtension2,
                    headerExtensionInstall,
                    footerBlogText,
                    footerBlogAssetLight,
                    footerBlogAssetDark,
                    footerLinks,
                    hamburgerLinks,
                    mobileHamburgerTagline,
                } = data.site.siteMetadata[lang]

                const themedBlogAsset = {
                    light: footerBlogAssetLight,
                    air: footerBlogAssetLight,
                    dark: footerBlogAssetDark,
                    night: footerBlogAssetDark,
                }

                return (
                    <OuterLayout
                        title={title}
                        description={description}
                        canonicalPath={canonicalPath}
                        themeStyles={themeStyles}
                        noindex={noindex}
                        lang={lang}
                    >
                        {errorHeader ? (
                            <HeaderError
                                links={hamburgerLinks}
                                hamburgerMenuNavigationLinks={hamburgerLinks}
                                headerTitle={headerTitle}
                                headerCarrotAsset={headerCarrotAsset}
                                headerSettingsLink={headerSettingsLink}
                                headerPrivacyAsset={headerPrivacyAsset}
                                headerPrivacyAssetDark={headerPrivacyAssetDark}
                                headerPrivacyDescription={headerPrivacyDescription}
                                headerPrivacyLink={headerPrivacyLink}
                                headerSettingsDescription={headerSettingsDescription}
                                headerSettingsAsset={headerSettingsAsset}
                                headerSettingsTitle={headerSettingsTitle}
                                headerHowLink={headerHowLink}
                                headerBlogDescription={headerBlogDescription}
                                headerBlogLink={headerBlogLink}
                                headerExtension1={headerExtension1}
                                headerExtensionBold={headerExtensionBold}
                                headerExtension2={headerExtension2}
                                headerExtensionInstall={headerExtensionInstall}
                                theme={theme}
                                themeStyles={themeStyles}
                                lang={lang}
                                errorMsg={errorHeader}
                                mobileHamburgerTagline={mobileHamburgerTagline}
                            />
                        ) : (
                            <Header
                                links={hamburgerLinks}
                                hamburgerMenuNavigationLinks={hamburgerLinks}
                                title={bannerTitle}
                                headerTitle={headerTitle}
                                headerCarrotAsset={headerCarrotAsset}
                                headerSettingsLink={headerSettingsLink}
                                headerPrivacyAsset={headerPrivacyAsset}
                                headerPrivacyAssetDark={headerPrivacyAssetDark}
                                headerPrivacyDescription={headerPrivacyDescription}
                                headerPrivacyLink={headerPrivacyLink}
                                headerSettingsDescription={headerSettingsDescription}
                                headerSettingsAsset={headerSettingsAsset}
                                headerSettingsTitle={headerSettingsTitle}
                                headerHowLink={headerHowLink}
                                headerBlogDescription={headerBlogDescription}
                                headerBlogLink={headerBlogLink}
                                hidePrivacyDropdown={hidePrivacyDropdown}
                                theme={theme}
                                themeStyles={themeStyles}
                                lang={lang}
                                overrideStyles={headerOverrideStyles}
                            />
                        )}
                        {children}
                        {(!errorHeader && !noFooter) && (
                            <Footer
                                links={footerLinks}
                                theme={theme}
                                blogText={footerBlogText}
                                blogAsset={themedBlogAsset[theme]}
                                themeStyles={themeStyles}
                                languageCode={lang}
                            />
                        )}
                    </OuterLayout>
                )
            }}
        />
    )

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    lang: PropTypes.string,
    canonicalPath: PropTypes.string.isRequired,
    themeStyles: PropTypes.object,
}

Layout.defaultProps = {
    themeStyles: {},
}

export default Layout
