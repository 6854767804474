import React from 'react'
import PropTypes from 'prop-types'
import {createMarkup} from '../graphql/datoCmsDataFactory'

const Markup = ({markup}) => <div dangerouslySetInnerHTML={createMarkup(markup)} />

Markup.propTypes = {
    markup: PropTypes.string,
}

Markup.defaultProps = {
    markup: '',
}

export default Markup
