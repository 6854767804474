/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {useState, useRef} from 'react'
import useOnClickOutside from '../util/useOnClickOutside'
import {convertDatoCmsImgPathsToLocal} from '../graphql/datoCmsDataFactory'
import {getStartpageBaseOrigin} from '../util/url'

const PrivacyDropdown = ({
    title,
    carrotAsset,
    privacyAsset,
    privacyAssetDark,
    privacyLink,
    theme,
    themeStyles,
    howLinkText,
    blogDescription,
    blogLink,
    lang,
}) => {
    const ref = useRef(null)
    const [showDropdown, setShowDropdown] = useState(false)
    useOnClickOutside(ref, () => setShowDropdown(false))

    const baseOrigin = getStartpageBaseOrigin()

    const themedPrivacyAsset = {
        light: privacyAsset,
        air: privacyAsset,
        dark: privacyAssetDark,
        night: privacyAssetDark,
    }

    return (
        <div
            ref={ref}
            css={css`
                position: relative;
                margin-right: 15px;
                @media (max-width: 950px) {
                    visibility: hidden;
                    min-width: 1px;
                }
            `}
        >
            <div
                css={css`
                    border: none;
                    color: ${themeStyles.primaryTextColor};
                    font-weight: 500;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    padding: 5px 10px;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    border-radius: 4px;
                    line-height: 26px;
                    span {
                        margin-right: 0.5rem;
                        color: inherit;
                    }
                    &:hover {
                        background-color: ${themeStyles.highlightBackgroundColor};
                        color: ${themeStyles.brandBlue};
                    }
                `}
                role="button"
                tabIndex="0"
                onKeyUp={() => setShowDropdown((prev) => !prev)}
                onClick={() => setShowDropdown((prev) => !prev)}
            >
                <span>{title}</span>
                <div
                    css={css`
                        width: 16px;
                        height: 16px;
                        background-color: ${themeStyles.highlightBackgroundColor};
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                    `}
                >
                    <img
                        css={css`
                            path {
                                fill: ${themeStyles.primaryTextColor};
                            }
                            transform: ${showDropdown ? 'none' : 'rotate(180deg)'};
                            width: 10px;
                            height: 10px;
                            filter: ${['dark', 'night'].includes(theme) ? 'brightness(0) invert(1)' : 'none'};
                        `}
                        src={convertDatoCmsImgPathsToLocal(carrotAsset.url)}
                        alt=""
                    />
                </div>
            </div>
            <div
                className="privacy-please-card"
                css={css`
                    position: absolute;
                    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    border: 1px solid ${themeStyles.boxBorderColor};
                    display: flex;
                    width: 408px;
                    right: 0;
                    text-align: center;
                    margin-top: 0.25rem;
                    padding: 20px;
                    z-index: 9999;
                    opacity: ${showDropdown ? 1 : 0};
                    visibility: ${showDropdown ? 'visible' : 'hidden'};
                    background-color: ${themeStyles.primaryBackgroundColor};
                    .link {
                        display: flex;
                        color: inherit;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        height: 50%;
                        text-align: left;
                        align-items: center;
                        padding-top: 24px;
                        padding-bottom: 8px;
                        margin-right: 20px;
                        &:first-of-type {
                            border-bottom: solid 1px ${themeStyles.boxBorderColor};
                            padding-top: 8px;
                            padding-bottom: 20px;
                        }
                    }
                    .link-text {
                        text-decoration: underline;
                        color: ${themeStyles.primaryTextColor};
                        &:hover {
                            color: ${themeStyles.linkColor};
                        }
                    }s
                `}
            >
                <div
                    css={css`
                        position: absolute;
                        top: 3px;
                        right: 3px;
                        width: 20px;
                        height: 20px;
                        background: url(${baseOrigin}/sp/cdn/images/grey-x.svg) 50% 50% no-repeat;
                        cursor: pointer;
                    `}
                    className="x-button"
                    onClick={() => setShowDropdown(false)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setShowDropdown(false)
                        }
                    }}
                    role="button"
                    tabIndex={0}
                    aria-label="close privacy dropdown"
                />
                <div
                    className="privacy-please-card__left"
                    css={css`
                        width: 58%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        border-right: 1px solid ${themeStyles.boxBorderColor};
                        padding: 0;
                        text-align: center;
                        .link-text {
                            padding-left: 12px;
                        }
                    `}
                >
                    <div className="link">
                        <img
                            src={
                                theme === 'light'
                                    ? `${baseOrigin}/sp/cdn/images/privacy-please-lock-icon.svg`
                                    : `${baseOrigin}/sp/cdn/images/privacy-please-lock-icon-dark.svg`
                            }
                            alt="Lock with shield"
                            css={css`
                                width: 40px;
                            `}
                        />
                        <a href={`${baseOrigin}/${lang}/how-startpage-works/`} className="link-text">
                            {howLinkText}
                        </a>
                    </div>
                    <div className="link">
                        <img
                            src={
                                theme === 'light'
                                    ? `${baseOrigin}/sp/cdn/images/privacy-please-av-icon.svg`
                                    : `${baseOrigin}/sp/cdn/images/privacy-please-av-icon-dark.svg`
                            }
                            alt="Lock with shield"
                            css={css`
                                width: 40px;
                            `}
                        />
                        <a href={`https://www.startpage.com/${lang}/anonymous-view/`} className="link-text">
                            {privacyLink}
                        </a>
                    </div>
                </div>
                <div
                    className="privacy-please-card__right"
                    css={css`
                        width: 42%;
                        text-align: center;
                        margin-left: 20px;
                    `}
                >
                    <img src={convertDatoCmsImgPathsToLocal(themedPrivacyAsset[theme].url)} alt="" />
                    <p
                        css={css`
                            margin: 6px 0 0 0;
                            color: ${themeStyles.primaryTextColor};
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 400;
                        `}
                    >
                        {title}
                    </p>
                    <p
                        css={css`
                            color: ${themeStyles.tertiaryTextColor};
                            margin: 4px 0 8px 0;
                            line-height: 18px;
                            font-size: 12px;
                        `}
                    >
                        {blogDescription}
                    </p>
                    <a
                        href={
                            lang === 'de'
                                ? `https://www.startpage.com/nix-zu-verbergen/`
                                : `https://www.startpage.com/privacy-please/`
                        }
                        className="link-text"
                        css={css`
                            font-size: 14px;
                            line-height: 20px;
                        `}
                    >
                        {blogLink}
                    </a>
                </div>
            </div>
        </div>
    )
}

export default PrivacyDropdown
