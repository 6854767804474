import {IS_DEV, ENV_BASE_DOMAINS} from '../env'

const {PROD: PROD_DOMAIN} = ENV_BASE_DOMAINS

/* eslint-disable import/prefer-default-export */
export const getUrlParameterByName = (name, url) => {
    // we need to check for document here, otherwise during build,
    // gatsby will throw "document is undefined" during server-side rendering
    if (typeof window === `undefined`) {
        return null
    }
    const location = url || window.location.href
    const replacedName = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp(`[?&]${replacedName}(=([^&#]*)|&|#|$)`)
    const results = regex.exec(location)
    if (!results) return null
    if (!results[2]) return ''
    return window.decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const isValidUrl = (url) => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i',
    ) // fragment locator
    return !!pattern.test(url)
}

/**
 * this function was added so we can dynamically update the base domain for
 * FE assets / network calls from absolute to relative path depending on the env
 * @returns string; 'https://www.startpage.com' or ''
 */
export const getStartpageBaseOrigin = () => {
    let IS_S1_SP = false
    if (typeof window !== `undefined`) {
        if (window.location.origin.includes('s1-sp')) IS_S1_SP = true
    }
    // on local dev, localhost:8000, or on static-stage.s1-sp.com or static-prod.s1-sp.com:
    if (IS_DEV || IS_S1_SP) return PROD_DOMAIN
    // on peak2, eu9 or prod:
    return ``
}
