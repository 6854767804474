/* eslint-disable camelcase */
import {useStaticQuery, graphql} from 'gatsby'
import {IS_DEV} from '../env'

// NOTE: we support the following locales on startpage
// en
// de
// da
// es
// fr
// nl
// no
// pl
// pt
// sv

const LINKS_MAP = (languageCode) => {
    const supportLang = new Set(['en', 'de', 'nl']).has(languageCode) ? languageCode : 'en'
    return {
        Settings: {
            link: `https://www.startpage.com/search/settings?lang=${languageCode}`,
            order: 0,
        },
        Support: {
            link: `https://support.startpage.com/index.php?${supportLang}/Knowledgebase/List`,
            order: 1,
        },
        Mail: {
            link: `https://www.startmail.com/${languageCode === 'de' ? languageCode : 'en'}/startpage/?pk_campaign=startpage`,
            order: 2,
        },
        'Privacy policy': {
            link: `/${languageCode}/privacy-policy/`,
            order: 3,
        },
        'About us': {
            link: `/${languageCode}/about-us/`,
            order: 4,
        },
        Blog: {
            link: `https://www.startpage.com/${languageCode === 'de' ? 'blog-de' : 'privacy-please'}/`,
            order: 5,
        },
        Press: {
            link: `https://www.startpage.com/privacy-please/press/`,
            order: 6,
        },
    }
}
const generateLinksData = (linksArray, linksCmsData, languageCode) => {
    const localizedNavigationLinks = linksCmsData[languageCode]
    return localizedNavigationLinks
        .map((linkObj) => {
            if (linksArray.includes(linkObj.englishTitle)) {
                return {...LINKS_MAP(languageCode)[linkObj.englishTitle], title: linkObj.title}
            }
            return undefined
        })
        .filter((res) => res !== undefined)
        .sort((a, b) => a.order - b.order)
}

const querySiteMetadata = () => {
    // NOTE: grapqhl query fragments like ...AboutUs etc. are found in /src/graphqlQueries/.
    // see documentation for using modular graphql fragments with gatsby here https://www.gatsbyjs.org/docs/graphql-concepts/#fragments

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data = useStaticQuery(
        graphql`
            query SiteMeta {
                site {
                    siteMetadata {
                        ...OffLine
                        ...NavigationLinks
                    }
                }
            }
        `,
    )
    return {
        ...data.site.siteMetadata,
    }
}

export const localizedLegacyDatoCmsData = (languageCode) => {
    const data = querySiteMetadata()
    const {off_line, navigationLinks} = data

    const defaultHeaderNavigationLinks = generateLinksData(
        ['Settings', 'Support', 'Mail'],
        navigationLinks,
        languageCode,
    )
    const footerNavigationLinks = generateLinksData(
        ['Privacy policy', 'About us', 'Blog', 'Press'],
        navigationLinks,
        languageCode,
    )
    const hamburgerMenuNavigationLinks = generateLinksData(
        ['Settings', 'Support', 'Mail', 'Privacy policy', 'About us', 'Blog', 'Press'],
        navigationLinks,
        languageCode,
    )

    return {
        off_line: off_line[languageCode],
        defaultHeaderNavigationLinks,
        footerNavigationLinks,
        hamburgerMenuNavigationLinks,
    }
}

export const convertDatoCmsImgPathsToLocal = (str) => {
    if (!str) return str
    let replacement = '/datoCmsDownload'
    if (!IS_DEV) {
        replacement = `/static-pages-assets${replacement}`
    }
    return str.replace(/https:\/\/www\.datocms-assets\.com/g, replacement)
}

export const createMarkup = (htmlString) => {
    return {
        __html: convertDatoCmsImgPathsToLocal(htmlString),
    }
}
