import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {css} from '@emotion/core'
import StartpageLogo from '../images/startpage-logo-gradient.svg'
import StartpageLogoDark from '../images/startpage-logo-gradient-dark.svg'
import HamburgerMenu from './HamburgerMenu'
import PrivacyDropdown from './PrivacyDropdown'
import {themes} from '../util/theme'

const Header = ({
    title,
    theme,
    themeStyles,
    hamburgerMenuNavigationLinks,
    headerTitle,
    headerCarrotAsset,
    headerSettingsLink,
    headerPrivacyAsset,
    headerPrivacyAssetDark,
    headerPrivacyDescription,
    headerPrivacyLink,
    headerSettingsDescription,
    headerSettingsAsset,
    headerSettingsTitle,
    overrideStyles = ``,
    headerHowLink,
    headerBlogDescription,
    headerBlogLink,
    hidePrivacyDropdown = false,
    lang,
}) => {
    const {primaryTextColor, darkerBackgroundColor} = themeStyles

    const [startpageLogo, setStartpageLogo] = useState(<StartpageLogo />)

    useEffect(() => {
        if (theme !== 'light' && theme !== 'air') setStartpageLogo(<StartpageLogoDark />)
    }, [theme])

    return (
        <header
            className="header"
            css={css`
                position: fixed;
                top: 0;
                left: 0;
                z-index: 999;
                width: 100%;
                padding: 1.6rem 2rem;
                background-color: ${darkerBackgroundColor};
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 1px 2px 2px rgb(0 0 0 / 5%);
                ${overrideStyles}
            `}
        >
            <div
                className="startpage-logo"
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <a
                    href={`https://www.startpage.com/?t=${theme}`}
                    css={css`
                        display: flex;
                        align-items: center;
                        svg {
                            height: 24px;
                            width: 104px;
                        }
                    `}
                    aria-label="Startpage homepage"
                    title="Startpage homepage"
                >
                    {startpageLogo}
                </a>
            </div>
            <div
                css={css`
                    h1 {
                        color: ${primaryTextColor};
                        font-size: 1.25rem;
                        font-weight: 600;
                        text-align: center;
                        @media (max-width: 600px) {
                            display: none;
                        }
                    }
                `}
            >
                {title && <h1>{title}</h1>}
            </div>
            <div 
                className="privacy-hamburger-container"
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                {(headerCarrotAsset && !hidePrivacyDropdown) && (
                    <PrivacyDropdown
                        title={headerTitle}
                        carrotAsset={headerCarrotAsset}
                        settingsLink={headerSettingsLink}
                        privacyAsset={headerPrivacyAsset}
                        privacyAssetDark={headerPrivacyAssetDark}
                        privacyDescription={headerPrivacyDescription}
                        privacyLink={headerPrivacyLink}
                        settingsDescription={headerSettingsDescription}
                        settingsAsset={headerSettingsAsset}
                        settingsTitle={headerSettingsTitle}
                        howLinkText={headerHowLink}
                        blogDescription={headerBlogDescription}
                        blogLink={headerBlogLink}
                        theme={theme}
                        themeStyles={themeStyles}
                        lang={lang}
                    />
                )}
                <div>
                    <HamburgerMenu theme={theme} themeStyles={themeStyles} links={hamburgerMenuNavigationLinks} />
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    title: PropTypes.string,
    theme: PropTypes.string,
    themeStyles: PropTypes.object,
    hamburgerMenuNavigationLinks: PropTypes.array,
    fontSizeTitle: PropTypes.string,
}

Header.defaultProps = {
    theme: `light`,
    themeStyles: themes.light,
    hamburgerMenuNavigationLinks: [
        {
            text: `Settings`,
            link: `https://www.startpage.com/search/settings?lang=en`,
        },
        {
            text: `Support`,
            link: `https://support.startpage.com/index.php?/Knowledgebase/List`,
        },
        {text: `StartMail`, link: `https://www.startmail.com/en/startpage/?pk_campaign=startpage`},
        {text: `Privacy policy`, link: `/en/privacy-policy/`},
        {text: `About Us`, link: `/en/about-us/`},
        {text: `Blog`, link: `https://www.startpage.com/privacy-please/`},
        {
            text: `Press`,
            link: `https://www.startpage.com/privacy-please/press/`,
        },
    ],
    fontSizeTitle: `2rem`,
}

export default Header
