/* eslint-disable import/prefer-default-export */
import {css} from '@emotion/core'

import AfterallSerifRegularWoff2 from './fonts/afterall-serif-regular-woff2.woff2'
import AfterallSerifRegularWoff from './fonts/afterall-serif-regular-woff.woff'
import Inter from './fonts/Inter.js'

export const globalStyles = css`
    ${Inter}

    @font-face {
        font-family: 'Afterall Serif';
        src: url(${AfterallSerifRegularWoff2}) format('woff2'), url(${AfterallSerifRegularWoff}) format('woff');
    }

    html {
        box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    * {
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
            Droid Sans, Helvetica Neue, sans-serif;
        color: #374677;
    }

    html,
    body,
    main,
    #___gatsby,
    #gatsby-focus-wrapper {
        height: 100%;
        margin: 0;
    }

    h1 {
        margin: 0;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    a {
        color: #374677;
    }

    p {
        // line-height: 1.6;
        margin: 0;
        color: #374677;
    }

    strong {
        font-weight: bold;
        font-family: 'Inter';
    }

    button,
    input[type='radio'],
    input[type='checkbox'] {
        &:hover {
            cursor: pointer;
        }
    }
`

export const supportedLanguages = [`en`, `de`, `da`, `es`, `fr`, `nl`, `no`, `pl`, `pt`, `sv`]
