/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {supportedLanguages} from '../constants'
import {stripTags} from '../util/html'
import {osPrefersDarkTheme} from '../util/theme'
import {getStartpageBaseOrigin} from '../util/url'

const baseOrigin = getStartpageBaseOrigin()

export const makeCanonicalAlternateLinks = (canonicalPath) => {
    return supportedLanguages.map((lang) => {
        return {
            rel: `alternate`,
            href: `${baseOrigin}/${lang}${canonicalPath}`,
            hreflang: lang,
        }
    })
}

export const favicons = () => [
    {
        rel: 'apple-touch-icon-precomposed',
        sizes: '57x57',
        href: `${baseOrigin}/sp/cdn/favicons/apple-icon-57x57.png`,
    },
    {
        rel: 'apple-touch-icon-precomposed',
        sizes: '60x60',
        href: `${baseOrigin}/sp/cdn/favicons/apple-icon-60x60.png`,
    },
    {
        rel: 'apple-touch-icon-precomposed',
        sizes: '72x72',
        href: `${baseOrigin}/sp/cdn/favicons/apple-icon-72x72.png`,
    },
    {
        rel: 'apple-touch-icon-precomposed',
        sizes: '76x76',
        href: `${baseOrigin}/sp/cdn/favicons/apple-icon-76x76.png`,
    },
    {
        rel: 'apple-touch-icon-precomposed',
        sizes: '114x114',
        href: `${baseOrigin}/sp/cdn/favicons/apple-icon-114x114.png`,
    },
    {
        rel: 'apple-touch-icon-precomposed',
        sizes: '120x120',
        href: `${baseOrigin}/sp/cdn/favicons/apple-icon-120x120.png`,
    },
    {
        rel: 'apple-touch-icon-precomposed',
        sizes: '144x144',
        href: `${baseOrigin}/sp/cdn/favicons/apple-icon-144x144.png`,
    },
    {
        rel: 'apple-touch-icon-precomposed',
        sizes: '152x152',
        href: `${baseOrigin}/sp/cdn/favicons/apple-icon-152x152.png`,
    },
    {
        rel: 'apple-touch-icon-precomposed',
        sizes: '180x180',
        href: `${baseOrigin}/sp/cdn/favicons/apple-icon-180x180.png`,
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '192x192',
        href: `${baseOrigin}/sp/cdn/favicons/android-icon-192x192.png`,
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: `${baseOrigin}/sp/cdn/favicons/favicon-32x32-gradient.png`,
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: `${baseOrigin}/sp/cdn/favicons/favicon-96x96.png`,
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: `${baseOrigin}/sp/cdn/favicons/favicon-16x16-gradient.png`,
    },
    {
        rel: 'shortcut icon',
        type: 'image/x-icon',
        href: `${baseOrigin}/sp/cdn/favicons/favicon-gradient.ico`,
    },
]

export const links = (lang, canonicalPath, theme) => [
    {
        rel: 'canonical',
        href: `${baseOrigin}/${lang}${canonicalPath}`,
    },
    {
        rel: `alternate`,
        href: `${baseOrigin}/en${canonicalPath}`,
        hreflang: `en-US`,
    },
    {
        rel: 'alternate',
        href: `${baseOrigin}/${lang}${canonicalPath}`,
        hreflang: 'x-default',
    },
    {
        href: `${baseOrigin}/sp/cdn/images/logo-safari-pinned-tab.svg`,
        rel: `mask-icon`,
        color: `#6573ff`,
    },
    {
        href: `${baseOrigin}/sp/cdn/images/apple_splash_2048.png`,
        rel: `apple-touch-startup-image`,
        sizes: `2048x2732`,
    },
    {
        href: `${baseOrigin}/sp/cdn/images/apple_splash_1668.png`,
        rel: `apple-touch-startup-image`,
        sizes: `1668x2224`,
    },
    {
        href: `${baseOrigin}/sp/cdn/images/apple_splash_1536.png`,
        rel: `apple-touch-startup-image`,
        sizes: `1536x2048`,
    },
    {
        href: `${baseOrigin}/sp/cdn/images/apple_splash_1125.png`,
        rel: `apple-touch-startup-image`,
        sizes: `1125x2436`,
    },
    {
        href: `${baseOrigin}/sp/cdn/images/apple_splash_1242.png`,
        rel: `apple-touch-startup-image`,
        sizes: `1242x2208`,
    },
    {
        href: `${baseOrigin}/sp/cdn/images/apple_splash_750.png`,
        rel: `apple-touch-startup-image`,
        sizes: `750x1334`,
    },
    {
        href: `${baseOrigin}/sp/cdn/images/apple_splash_640.png`,
        rel: `apple-touch-startup-image`,
        sizes: `640x1136`,
    },
    ...makeCanonicalAlternateLinks(canonicalPath),
    ...favicons(theme),
]

export const metaTags = (title, description, lang, canonicalPath, noindex, theme) => [
    {
        name: `description`,
        content: stripTags(description),
    },
    {
        name: `og:url`,
        content: `${baseOrigin}/${lang}${canonicalPath}`,
    },
    {
        property: `og:title`,
        content: title,
    },
    {
        property: `og:description`,
        content: stripTags(description),
    },
    {
        property: `og:type`,
        content: `website`,
    },
    {
        property: `og:image`,
        content: `https://www.startpage.com/sp/cdn/favicons/mstile-310x310--${theme}.png`,
    },
    {
        property: `og:image:secure_url`,
        content: `https://www.startpage.com/sp/cdn/favicons/mstile-310x310--${theme}.png`,
    },
    {
        property: `og:image:height`,
        content: `310`,
    },
    {
        property: `og:image:width`,
        content: `310`,
    },
    {
        property: `og:site_name`,
        content: `www.startpage.com`,
    },
    {
        name: `twitter:card`,
        content: `summary`,
    },
    {
        name: `twitter:title`,
        content: title,
    },
    {
        name: `twitter:description`,
        content: stripTags(description),
    },
    {
        name: `twitter:site`,
        content: `@startpage`,
    },
    {
        property: `twitter:image`,
        content: `${baseOrigin}/favicons/mstile-310x310--${theme}.png`,
    },
    {
        name: `referrer`,
        content: `no-referrer-when-downgrade`,
    },
    {
        name: `robots`,
        content: `${noindex ? 'noindex, follow, ' : ''}max-snippet:-1, max-image-preview:large, max-video-preview:-1`,
    },
    {
        name: `apple-mobile-web-app-capable`,
        content: `yes`,
    },
    {
        name: `msapplication-TileColor`,
        content: `#FFFFFF`,
    },
    {
        name: `theme-color`,
        content: `#6573ff`,
    },
    {
        name: 'msapplication-TileImage',
        content: `${baseOrigin}/ms-icon-144x144.png`,
    },
    {
        name: 'msapplication-square70x70logo',
        content: `${baseOrigin}/favicons/ms-icon-70x70.png`,
    },
    {
        name: 'msapplication-square150x150logo',
        content: `${baseOrigin}/favicons/ms-icon-150x150.png`,
    },
    {
        name: 'msapplication-square310x310logo',
        content: `${baseOrigin}/favicons/ms-icon-310x310.png`,
    },
]

// eslint-disable-next-line no-unused-vars
function SEO({title, description, lang, meta, canonicalPath, noindex}) {
    const theme = osPrefersDarkTheme() ? 'dark' : 'light'
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={title}
            link={links(lang, canonicalPath, theme)}
            meta={metaTags(title, description, lang, canonicalPath, noindex, theme).concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    title: `startpage.com`,
    description: `The world's most private search engine`,
    canonicalPath: `/`,
}

SEO.propTypes = {
    description: PropTypes.string.isRequired,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    canonicalPath: PropTypes.string.isRequired,
}

export default SEO
